import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Table, Form, FormGroup, Label, Button } from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import './CraPage.scss'

const getDaysInMonth = (month, year) => {
    const date = new Date(year, month - 1, 1);
    const days = [];
    while (date.getMonth() === month - 1) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
};

const Cra = () => {
    const [client, setClient] = useState("");
    const [mission, setMission] = useState("");
    const [month, setMonth] = useState(12);
    const [year, setYear] = useState(2024);
    const [daysInMonth, setDaysInMonth] = useState([]);

    useEffect(() => {
        const daysArray = getDaysInMonth(month, year).map(date => ({
            date,
            serviceDay: 0,
            offDay: 0,
            onSiteTelework: 'O'
        }));
        setDaysInMonth(daysArray);
    }, [month, year]);
    const toggleOnSiteTelework = (index) => {
        const newDaysInMonth = [...daysInMonth];
        newDaysInMonth[index].onSiteTelework = newDaysInMonth[index].onSiteTelework === 'R' ? 'O' : 'R';
        setDaysInMonth(newDaysInMonth);
    };
    const handleServiceDayChange = (index, value) => {
        if (value === '') {
            value = 'null'; 
        }
        if (value === 'null') {
            value = '0';
        }
        if (value === '0' || value === '1' || value === '0.5') {
            const newDaysInMonth = [...daysInMonth];
            newDaysInMonth[index].serviceDay = parseFloat(value);
            setDaysInMonth(newDaysInMonth);
        }
    };
    const totalServicesDays = daysInMonth.reduce((total, day) => total + day.serviceDay, 0);
    //const totalOffDays = daysInMonth.reduce((total, day) => total + day.offDay, 0);

    return (

        <Widget>
            <Form>
                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label>Client</Label>
                            <Input type="text" value={client} onChange={(e) => setClient(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Mission</Label>
                            <Input type="text" value={mission} onChange={(e) => setMission(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Mois</Label>
                            <Input type="select" value={month} onChange={(e) => setMonth(parseInt(e.target.value))}>
                                {Array.from({ length: 12 }, (_, i) => i + 1).map(m => (
                                    <option key={m} value={m}>{m}</option>
                                ))}
                            </Input>
                        </FormGroup>    
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Année</Label>
                            <Input type="select" value={year} onChange={(e) => setYear(parseInt(e.target.value))}>
                                {Array.from({ length: 10 }, (_, i) => 2024 + i).map(y => (
                                    <option key={y} value={y}>{y}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table bordered className="cra-table">
                            <thead>
                                <tr>
                                    <th>Semaine</th>
                                    {daysInMonth.map((day, index) => (
                                        <th key={index}>{getWeekNumber(day.date)}</th>
                                    ))}
                                    <th>Total</th>
                                </tr>
                                <tr>
                                    <th>Jours</th>
                                    {daysInMonth.map((day, index) => (
                                        <th key={index} className={isWeekend(day.date) ? 'weekend' : 'Notweekend'}>
                                            {day.date.getDate()}
                                        </th>
                                    ))}
                                </tr>
                                <tr>
                                    <th></th>
                                    {daysInMonth.map((day, index) => (
                                        <th key={index} className={isWeekend(day.date) ? 'weekend' : 'Notweekend'}>{getDayOfWeek(day.date)}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Jours prestés</td>
                                    {daysInMonth.map((day, index) => (
                                        <td key={index} className={isWeekend(day.date) ? 'weekend' : ''}>
                                            <input className="cra_input"
                                                type="text"
                                                value={day.serviceDay}
                                                onChange={(e) => handleServiceDayChange(index, e.target.value)}
                                            />
                                        </td>
                                    ))}
                                    <td>{totalServicesDays}</td>
                                </tr>
                                <tr>
                                    <td>On site / Remote</td>
                                    {daysInMonth.map((day, index) => (
                                        <td key={index} onClick={() => toggleOnSiteTelework(index)} className={isWeekend(day.date) ? 'weekend' : ''}>{day.onSiteTelework}</td>
                                    ))}
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </Table>
                        <footer className="text-center my-4">
                            <p>Par le présent document, j'atteste que les travaux ont été effectués conformément aux règles et déclare leur validité. Bon pour facturation.</p>
                        </footer>
                    </Col>
                </Row>
                <Button className="text-right my-4">
                    Enregistrer CRA
                </Button>
            </Form>

        </Widget>
    );
};

function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}
function getDayOfWeek(date) {
    const daysOfWeek = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
    return daysOfWeek[date.getDay()];
}
function isWeekend(date) {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 pour dimanche, 6 pour samedi
}
export default Cra;

