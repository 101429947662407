import React from 'react';
import logo from '../../assets/logo.png'
class SofiaLogo extends React.Component {
  render() {
    return (
        <img
            className={this.props.className}
            src={logo}
            alt="Sofia Logo"
            width="80"
            height="60"
        />
    );
  }
}

export default SofiaLogo;