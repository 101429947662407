import React, { useEffect, useState } from 'react';
import { Route, Redirect } from "react-router-dom";
import { getAdressTypeAll } from '../services/configService';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            getAdressTypeAll()
                .then(response => {
                    // Check if token is expired based on your logic
                    // For example, you might compare current time with expiry time
                    const isExpired = false; // Replace with your logic
                    setIsTokenExpired(isExpired);
                })
                .catch(error => {
                    // Handle error
                    if (error.response && error.response.status === 401) {
                        // 401 unauthorized, consider token as expired
                        setIsTokenExpired(true);
                    } else {
                        console.error("Error while fetching address types:", error);
                    }
                });
        }
    }, [token]);

    if (token) {
        if (isTokenExpired) {
            return <Redirect to="/login" />;
        } else {
            return <Route {...rest} render={(props) => <Component {...props} />} />;
        }
    } else {
        return <Redirect to="/login" />;
    }
};

export default PrivateRoute;
