import React, { useEffect, useState } from "react";
import {
    Button, Input, Modal, ModalHeader, ModalBody,
    Row, Col, Form, FormGroup, Label, InputGroup, InputGroupAddon
} from "reactstrap";
import CustomAccordion from "../Accordion/CustomAccordion";
import RechartsPieChart from "../../pages/uielements/charts/components/RechartsPieChart";
import MultiOptionButton from "../MultiOptionButton/MultiOptionButton";
import CustomAccordion2 from "../Accordion/CustomAccordion2";
import { getEmployeeById, getEmployees, getSimulation, saveSimulation } from "../../services/configService";
import { formatNmbr } from '../../services/OutilService'
import { startLoading, stopLoading } from "../../actions/loading";
import { useDispatch } from "react-redux";
import showToast from "../NotificationToast/toastUtils";


const ModalAddProspection = (
    props
) => {
    const [data, setData] = useState({
        dailyPrice: "0",
        nbreOfDays: "0",
        savingAmount: "0",
        chiffreAffaire: "0",
        fraisDeGestion: "0",
        panierRepas: "0",
        fraisAnnexe: "0",
        ik: "0",
        ndf: "0",
        fraisprofessionnels: "0",
        primeDapportDaffaire: "0",
        indemniteCongePaye: "0",
        salaireBrute: "0",
        salaireNetImposable: "0",
        salaireOptimiseNet: "0",
        reserve: "0",
        reste: "0",
        compteCourant: "0",
        salaireBrutDebase: "0"
    });
    const [saveData, setSaveData] = useState({
        name: "",
        employees_id: null,
        fonction: "",
        email: ""
    })
    const [PieValues, setPieValues] = useState({
        v1: 0,
        v2: 0,
        v3: 0
    })

    const [employees, setEmployees] = useState([]);
    const [FraisProIk, setFraisProIk] = useState(0);
    const [ndf, setNdf] = useState(0)
    const [epargne, setEpargne] = useState(0)
    const dispatch = useDispatch();
    const handleOptionClick = async (option) => {
        if (option === "Option 1") {
            dispatch(startLoading());
            try {
                await saveSimulation({ data, saveData });
                showToast('La simulation a été enregistré avec succès.');
                setTimeout(() => {
                    window.location.reload(); 
                }, 2000);
            } catch (error) {
               showToast('La simulation n\'a pas pu être enregistré.' , "error");
            } finally {
                dispatch(stopLoading());
            }
        }
    }


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const res = await getEmployees();
            setEmployees(res.data);
        } catch (error) {
            showToast(error , "error");
        }
    };

    const handleClickCalculate = async () => {
        dispatch(startLoading());
        try {
            const res = await getSimulation(data.dailyPrice, data.nbreOfDays, FraisProIk, ndf, epargne)
            setData(res.data)
            setPieValues({
                v1: res.data.salaireOptimiseNet,
                v2: res.data.fraisDeGestion,
                v3: res.data.fraisAnnexe
            });
        } catch (error) {
            showToast('Une erreur s\'est produite : ' , "error");
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleSelectConsultant = async (e) => {
        dispatch(startLoading());
        try {
            const res = await getEmployeeById(e);
            const { firstName, lastName, email, duty, id } = res.data;
            setSaveData(prevData => ({
                ...prevData,
                name: `${firstName} ${lastName}`,
                email: email,
                fonction: duty,
                employees_id: id
            }));
        } catch (error) {
            console.error('Une erreur s\'est produite : ', error);
        } finally {
            dispatch(stopLoading());
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleChangeTosave = (e) => {
        const { name, value } = e.target;
        setSaveData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    return (
        <Modal isOpen={props.modalOpen} toggle={props.toggleModal} className="add-porspection">
            <ModalHeader toggle={props.toggleModal}>Create Prospection</ModalHeader>
            <ModalBody>
                <Row>
                    <Col className="panel left-panel" md={6}>
                        <Form className="Form-prospection">
                            <FormGroup className="row">
                                <Label for="consultant" className="col-sm-6">Consultant</Label>
                                <Input type="select" name="consultant" onChange={(e) => handleSelectConsultant(e.target.value)} id="consultant" className="col-sm-6">
                                    <option>Select...</option>
                                    {employees.map(emp => (
                                        <option value={emp.id}>{emp.firstName} | {emp.lastName}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label for="name" className="col-sm-6">Nom</Label>
                                <Input type="text" name="name" id="name" placeholder="le nom du consultant" className="col-sm-6" value={saveData.name} onChange={handleChangeTosave} />
                            </FormGroup>
                            <FormGroup className="row">
                                <Label for="fonction" className="col-sm-6">Fonction</Label>
                                <Input type="text" name="fonction" id="fonction" placeholder="la fonction du consultant" className="col-sm-6" value={saveData.fonction} onChange={handleChangeTosave} />
                            </FormGroup>
                            <FormGroup className="row">
                                <Label for="email" className="col-sm-6">Email</Label>
                                <Input type="email" name="email" id="email" placeholder="email du consultant" className="col-sm-6" value={saveData.email} onChange={handleChangeTosave} />
                            </FormGroup>
                            <FormGroup className="row">
                                <Label for="tjm" className="col-sm-6">TJM</Label>
                                <Input type="number" name="dailyPrice" id="tjm"
                                    value={data.dailyPrice}
                                    onChange={handleChange}
                                    className="col-sm-6" />
                            </FormGroup>
                            <FormGroup className="row">
                                <Label for="days" className="col-sm-6">Nombre de jours</Label>
                                <Input type="number" name="nbreOfDays" id="days" className="col-sm-6"
                                    min={0} max={30}
                                    value={data.nbreOfDays}
                                    onChange={handleChange} />
                            </FormGroup>
                            <CustomAccordion
                                nbreOfDays={data.nbreOfDays}
                                ndf={ndf}
                                setNdf={setNdf}
                                FraisProIk={FraisProIk}
                                setFraisProIk={setFraisProIk}
                            />
                            <FormGroup className="row ">
                                <InputGroup className="NDF-Input mt-2 ml-1">
                                    <Label for="days" className="col-sm-6">Épargne</Label>
                                    <Input className="ml-6" name="epargne" value={epargne} onChange={(e) => setEpargne(e.target.value)} />
                                    <InputGroupAddon addonType="append">€</InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                            <Button className="mb-3" onClick={handleClickCalculate} color="primary">Calculer</Button>
                            <CustomAccordion2 />
                        </Form>
                    </Col>
                    <Col className="panel right-panel" md={6}>
                        <table className="table-simulation">
                            <tbody>
                                <tr>
                                    <td>Nombre de jours</td>
                                    <td colspan="3">{data.nbreOfDays}</td>

                                </tr>
                                <tr>
                                    <td>TJM</td>
                                    <td colspan="3">{formatNmbr(data.dailyPrice)} €</td>
                                </tr>
                                <tr>
                                    <td>Chiffre d'affaire</td>
                                    <td colspan="3">{formatNmbr(data.chiffreAffaire)} €</td>
                                </tr>
                                <tr>
                                    <td>Frais de gestion</td>
                                    <td colspan="3">{formatNmbr(data.fraisDeGestion)} €</td>
                                </tr>
                                <tr>
                                    <td>Frais annexes (mutuelle, prevoyance, CVAE ...)</td>
                                    <td colspan="3">{formatNmbr(data.fraisAnnexe)} €</td>
                                </tr>
                                <tr>
                                    <td>Compte courant</td>
                                    <td colspan="3" >{formatNmbr(data.compteCourant)} €</td>
                                </tr>
                                <tr>
                                    <td rowspan="3">Frais professionnels</td>
                                    <td rowspan="3">{formatNmbr(data.fraisprofessionnels)} €</td>
                                    <td>Panier repas</td>
                                    <td>{formatNmbr(data.panierRepas)} €</td>
                                </tr>
                                <tr>
                                    <td>IK</td>
                                    <td>{formatNmbr(data.ik)} €</td>
                                </tr>
                                <tr>
                                    <td>NDF</td>
                                    <td>{formatNmbr(data.ndf)} €</td>
                                </tr>

                                <tr>
                                    <td>Epargne</td>
                                    <td colspan="3" >{formatNmbr(data.savingAmount)} €</td>
                                </tr>
                                <tr>
                                    <td>Reste (Compte courant - Frais Pro - Epargne)</td>
                                    <td colspan="3" >{formatNmbr(data.reste)} €</td>
                                </tr>
                                <tr>
                                    <td rowspan="3">Salaire brute total</td>
                                    <td rowspan="3">{formatNmbr(data.salaireBrute)} €</td>
                                    <td>Salaire brute de base</td>
                                    <td>{formatNmbr(data.salaireBrutDebase)} €</td>
                                </tr>
                                <tr>
                                    <td>Prime d'apport d'affaire</td>
                                    <td>{formatNmbr(data.primeDapportDaffaire)} €</td>
                                </tr>
                                <tr>
                                    <td>Indemnité congé payé</td>
                                    <td>{data.indeminiteCongePaye} €</td>
                                </tr>
                                <tr>
                                    <td>Salaire net imposable</td>
                                    <td colspan="3"  >{data.salaireNetImposable} €</td>
                                </tr>
                                <tr className="highlight-green">
                                    <td>Salaire optimisé</td>
                                    <td colspan="3" >{data.salaireOptimiseNet} €</td>
                                </tr>
                                <tr className="highlight-yellow">
                                    <td>Reserve</td>
                                    <td colspan="3" >{data.reserve} €</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="chart-container">
                            <RechartsPieChart data={PieValues} />
                        </div>
                        <MultiOptionButton handleOptionClick={handleOptionClick} />
                    </Col>
                </Row>
            </ModalBody >

        </Modal >
    )
}

export default ModalAddProspection