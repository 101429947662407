import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import './InputCustom.scss'
const InputCustom = ({
  label,
  type = 'text',
  id,
  placeholder,
  value,
  onChange,
  validate, // Nouvelle prop pour la validation
  required = false, // Prop pour rendre le champ obligatoire
  className = 'col-md-6 mb-3',
  error,
  ...rest
}) => {


  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <div className={className}>
      <FormGroup>
        <Label className="label-input"  for={id}>
          {label} {required && '*'}
        </Label>
        <Input
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          invalid={!!error}
          {...rest}
        />
        {error && <FormFeedback>{error}</FormFeedback>}
      </FormGroup>
    </div>
  );
};

// Définition des types des props
InputCustom.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
};

// Valeurs par défaut des props
InputCustom.defaultProps = {
  type: 'text',
  required: false,
  className: 'col-md-6 mb-3',
};

export default InputCustom;