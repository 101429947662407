import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup } from 'reactstrap';
import './OrganizationsPage.scss'
import { useHistory } from 'react-router-dom';
import { getOrganizations, getLegalstatusAll, saveOrganization, deleteOrganization } from '../../services/configService';
import { toast } from 'react-toastify';
import showToast from "../../components/NotificationToast/toastUtils";

const Organizations = () => {
  const [registrationDate, setDateEnregistrement] = useState(null);
  const [environments, setEnvironments] = useState([]);
  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const [legalStatus, setLegalStatus] = useState('');
  const [nomValid, setnomValid] = useState(true);
  const [legalStatusValid, setlegalStatusValid] = useState(true);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [legalStatusOptions, setLegalStatusOptions] = useState([]);
  const [name, setName] = useState('');
  const [siretNumber, setSiretNumber] = useState('');
  const [tvaNumber, setTvaNumber] = useState('');
  const [email, setEmail] = useState('')
  const [mobileNumber, setMobileNumber] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [website, setWebsite] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nameValid, setNameValid] = useState(true);
  const [siretNumberValid, setSiretNumberValid] = useState(true);
  const history = useHistory();
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(null);

  useEffect(() => {
    fetchLegalStatusOptions();
    fetchEnvironments();
  }, []);
  const options = {
    autoClose: 2000,
    closeButton: false,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
  };
  const fetchEnvironments = async () => {
    try {
      const initialEnvironments = await getOrganizations();
      setEnvironments(initialEnvironments.data);
    } catch (error) {
      console.error('Error fetching environments:', error);
    }
  };
  const fetchLegalStatusOptions = async () => {
    try {
      const res = await getLegalstatusAll();
      setLegalStatusOptions(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAdd = async () => {
    if (name.trim() === '') {
      setNameValid(false);
    } else {
      setNameValid(true);
    }

    if (siretNumber.trim() === '') {
      setSiretNumberValid(false);
    } else {
      setSiretNumberValid(true);
    }

    if (legalStatus.trim() === '') {
      setlegalStatusValid(false);
    } else {
      setlegalStatusValid(true);
    }

    if (name.trim() !== '' && siretNumber.trim() !== '' && legalStatus.trim() !== '') {
      try {
        await saveOrganization(
          name,
          siretNumber,
          mobileNumber,
          phoneNumber,
          legalStatus,
          tvaNumber,
          registrationDate,
          website,
          addressLine1,
          addressLine2,
          zipcode,
          city,
          country,
          email
        );
        showToast('L\'organisation a été enregistrée avec succès.' );
        toggleAddModal();
        fetchEnvironments();
      } catch (error) {
        showToast("L'organisation n'a pas pu être enregistrée. " + error.message , "error" );
      }
    }
  }
  function handleDivClick() {
    history.push("/home/prospections");
  }

  function handleDeleteIcon(e,id) {
    e.stopPropagation();
    setSelectedEnvironmentId(id);
    toggleDeleteModal();
  }

  const handleDelete = async () => {
    try {
      await deleteOrganization(selectedEnvironmentId);
      showToast('L\'Environnement sélectionné a été supprimé avec succès.' );
    } catch (error) {
      showToast('Une erreur s\'est produite lors de la suppression d\'Environnement.' ,"error" );
    }
    setSelectedEnvironmentId(null);
    toggleDeleteModal();
    fetchEnvironments();
  }

  
  return (
    <div className="Select-environement select-environment-background">
      <div className="d-flex align-items-center justify-content-center vh-100">
        <Container>
          <Row className="justify-content-center">
            <h1 className="text-center mb-4">Environnement de Travail</h1>
          </Row>
          <Row className="justify-content-center ">
            {environments.map((environment, index) => (
              <Col key={index} xs="4" className="text-center mb-3">
                <div className="environment-square" onClick={handleDivClick}>
                  <i className="eva eva-home-outline company-logo" />
                  <p className="body-1 mb-0">{environment.name}</p>
                  <p className="body-3 text-muted mb-0">{environment.adressLine1}</p>
                  <p className="body-3 text-muted mb-0">{environment.city}</p>
                  <p className="body-3 text-muted mb-0">{environment.country}</p>
                  <i className="eva eva-trash-2-outline delete-icon" onClick={(e) => handleDeleteIcon(e,environment.id)} />
                  <i className="eva eva-edit-outline edit-icon"/>
                </div>
              </Col>
            ))}
            <Col xs="4" className="text-center mb-3">
              <Button className="add-environment-btn" onClick={toggleAddModal}>
                +
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={isAddModalOpen} toggle={toggleAddModal}>
          <ModalHeader toggle={toggleAddModal}>Ajouter un environnement</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="nom">Nom Environnement:</Label>
              <Input
                type="text"
                id="nom"
                placeholder="Nom"
                value={name}
                onChange={(e) => setName(e.target.value)}
                valid={nomValid}
                invalid={!nomValid}
              />
            </FormGroup>
            <FormGroup>
              <Label for="nom">Numero Siret:</Label>
              <Input
                type="text"
                id="nom"
                placeholder="Nom"
                value={siretNumber}
                onChange={(e) => setSiretNumber(e.target.value)}

              />
            </FormGroup>
            <FormGroup>
              <Label for="nom">Numero Tva:</Label>
              <Input
                type="number"
                id="nom"
                placeholder="Nom"
                value={tvaNumber}
                onChange={(e) => setTvaNumber(e.target.value)}

              />
            </FormGroup>
            <FormGroup>
              <Label for="nom">Adresse mail:</Label>
              <Input
                type="email"
                id="email"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}

              />
            </FormGroup>
            <FormGroup>
              <Label for="legalStatus">Statut légal:</Label>
              <Input
                type="select"
                id="legalStatus"
                value={legalStatus}
                onChange={(e) => setLegalStatus(e.target.value)}
                valid={legalStatusValid}
                invalid={!legalStatusValid}
              >
                <option value="">Sélectionner un statut</option>
                {legalStatusOptions.map(option => (
                  <option key={option.id} value={option.id}>{option.codeLabel} - {option.label}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="dateEnregistrement">Date d'enregistrement:</Label>
              <Input
                type="date"
                id="dateEnregistrement"
                placeholder="Date d'enregistrement"
                value={registrationDate}
                onChange={(e) => setDateEnregistrement(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phoneNumber">Numéro Fix:</Label>
              <Input
                type="tel"
                id="phoneNumber"
                placeholder="Numéro mobile"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="mobileNumber">Numéro téléphone:</Label>
              <Input
                type="tel"
                id="mobileNumber"
                placeholder="Numéro mobile"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="addressLine1">Adresse 1:</Label>
              <Input
                type="text"
                id="addressLine1"
                placeholder="Adresse 1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="addressLine2">Adresse 2:</Label>
              <Input
                type="text"
                id="addressLine2"
                placeholder="Adresse 2"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="zipcode">Code postal:</Label>
              <Input
                type="number"
                id="zipcode"
                placeholder="Code postal"
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="city">Ville:</Label>
              <Input
                type="text"
                id="city"
                placeholder="Ville"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="country">Pays:</Label>
              <Input
                type="text"
                id="country"
                placeholder="Pays"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="website">Site web:</Label>
              <Input
                type="text"
                id="website"
                placeholder="Site web"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="website">Logo:</Label>
              <Input
                type="file"
                id="website"
                accept="image/*"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleAddModal}>
              Annuler
            </Button>
            <Button color="primary" onClick={handleAdd}>
              Ajouter
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>Supprimer Fournisseur</ModalHeader>
          <ModalBody>
            Voulez-vous vraiment supprimer l'environnement sélectionnés ?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDeleteModal}>
              Annuler
            </Button>
            <Button color="danger" onClick={handleDelete}>
              Supprimer
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default Organizations;