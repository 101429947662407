import React from "react";
import { v4 as uuidv4 } from "uuid";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import s from "../Charts.module.scss";
import Dot from "../../../../components/Dot/Dot";



const RechartsPieChart = ({ data }) => {
  const chartsSettings = {
    donut: {
      data: [
        { name: 'Net à payer', value: format(data.v1), color: '#15be56' },
      { name: 'Frais de gestion', value:format(data.v2), color: '#4154f1' },
      { name: 'Cotisations', value: format(data.v3), color: '#ff7043' }
      ],
    }
  };
  return (
    <div style={{ height: "316px" }}>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart >
          <Pie
            data={chartsSettings.donut.data}
            innerRadius={50}
            outerRadius={80}
            dataKey="value"
          >
            {chartsSettings.donut.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className={s.donutLabels}>
        {chartsSettings.donut.data.map((entry, index) => (
          <div key={uuidv4()} className={s.label}>
            <Dot color={entry.color} />
            <span className="body-3 ml-2">{entry.name}</span>
          </div>
        ))}
      </div>
    </div>
  )
};
function format(number) {
  if (typeof number === 'string') {
      return parseFloat(number.replace(',', '.'));
  } else {
      return number;
  }
}
export default RechartsPieChart;
