import React, { useEffect, useState } from "react";
import {
    Button, Input,
} from "reactstrap";
import { DragDropContext } from 'react-beautiful-dnd';
import './ProspectionsPage.scss';
import ModalAddProspection from "../../components/ModalAddProspection/ModalAddProspection";
import DynamicCardComponent from "../../components/DynamicCardComponent/DynamicCardComponent";
import { getSimulations, setEnModeNegotiation, setEnModeAnnulation, setEnModeSignature} from "../../services/configService";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../actions/loading";
import ContractCreationModal from "../../components/Modalecreationdecontrat/ContractCreationModal";
import CancelSignedModal from "../../components/CancelSignedModal/CancelSignedModal";
import NegociationModal from "../../components/NegociationModal/NegociationModal";
import showToast from "../../components/NotificationToast/toastUtils";
import {AddBox, Forum, Edit, Cancel} from "@material-ui/icons";

const Prospections = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [propositionUsers, setPropositionUsers] = useState([]);
    const [negotiationUsers, setNegotiationUsers] = useState([]);
    const [signedUsers, setSignedUsers] = useState([]);
    const [simulationId, setSimulationId] = useState(null);
    const dispatch = useDispatch();
    const [cancelledUsers, setCancelledUsers] = useState([]);
    const [modalContractOpen, setModalContractOpen] = useState(false);
    const [modalCancelOpen, setModalCancelOpen] = useState(false);
    const [ModalNegociationOpen, setModalNegociationOpen] = useState(false);

    const fetchData = async () => {
        dispatch(startLoading());
        try {
            const res = await getSimulations("S0001");
            setPropositionUsers(res.data);
            const res2 = await getSimulations("S0002");
            setNegotiationUsers(res2.data);
            const res3 = await getSimulations("S0003");
            setSignedUsers(res3.data);
            const res4 = await getSimulations("S0004");
            setCancelledUsers(res4.data);
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDragEnd = (result) => {
        const { source, destination, draggableId } = result;
        setSimulationId(draggableId)
        if (!destination) { return; }
        if (source.droppableId === "negotiation" && destination.droppableId === "signed") {
            toggleModalContract();
        }
        if (source.droppableId === "proposition" && destination.droppableId === "negotiation") {
            toggleModalNegociation();
        }
        if (source.droppableId === "signed" && destination.droppableId === "cancelled") {
            toggleModalCancel();
        }
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const toggleModalContract = async () => {
         setModalContractOpen(!modalContractOpen);
    };
    const toggleModalCancel = () => {
        setModalCancelOpen(!modalCancelOpen);
    }
    const toggleModalNegociation = () => {
        setModalNegociationOpen(!ModalNegociationOpen)
    }
    const handleValidateCancelation = async () => {
        dispatch(startLoading());
        try {
            await setEnModeAnnulation(simulationId)
            showToast('Le contrat a été résilié avec succès.');
        } catch (error) {
            showToast('Le contrat n\'a pas pu être résilié.' , "error");
        } finally {
            dispatch(stopLoading());
            toggleModalCancel();
            fetchData();
        }
    }
    const handleValidate = async (formData) => {
        dispatch(startLoading());
        try {
            await setEnModeSignature(simulationId , formData)
            showToast('La simulation a été modifié avec succès.');
        } catch (error) {
            showToast(`La simulation n'a pas pu être modifiée. Détail : ${error.response.data.message}` , "error");
        } finally {
            dispatch(stopLoading());
            toggleModalContract();
            fetchData();
        }
    }

    const handleValidateNegociation = async () => {
        dispatch(startLoading());
        try {
            await setEnModeNegotiation(simulationId)
            showToast('La simulation a été modifié avec succès.');
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            showToast('La simulation n\'a pas pu être modifié.' , "error");
        } finally {
            dispatch(stopLoading());
            toggleModalNegociation();
            fetchData();
        }
    }
    return (
        <div>
           <ContractCreationModal
                toggleModalContract={toggleModalContract}
                modalContractOpen={modalContractOpen}
                handleValidate={handleValidate}
                simulationId={simulationId}
            /> 
            <CancelSignedModal
                toggleModalCancel={toggleModalCancel}
                modalCancelOpen={modalCancelOpen}
                handleValidate={handleValidateCancelation}
            />
            <NegociationModal
                toggleModalNegociation={toggleModalNegociation}
                ModalNegociationOpen={ModalNegociationOpen}
                handleValidate={handleValidateNegociation}
            />
            <div className="header d-flex justify-content-between">
                <Input type="text" placeholder="Search" className="mr-2 col-sm-3" />
                <Button color="primary" onClick={toggleModal}>Create New</Button>
            </div>
            <div className="outer-container">
                <DragDropContext onDragEnd={handleDragEnd}>
                    <div className="container-card">
                        <DynamicCardComponent
                            icon={AddBox}
                            droppableId="proposition"
                            title="PROPOSITION"
                            users={propositionUsers}
                        />
                        <DynamicCardComponent
                            icon={Forum}
                            droppableId="negotiation"
                            title="NEGOTIATION"
                            users={negotiationUsers}
                        />
                        <DynamicCardComponent
                            icon={Edit}
                            droppableId="signed"
                            title="SIGNATURE"
                            users={signedUsers}
                        />
                        <DynamicCardComponent
                            icon={Cancel}
                            droppableId="cancelled"
                            title="RESILIATION"
                            users={cancelledUsers}
                        />
                    </div>
                </DragDropContext>
            </div>
            <ModalAddProspection modalOpen={modalOpen} toggleModal={toggleModal} />
        </div>
    );
}

export default Prospections;