
import Axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_URL;;

export function login(email, password) {
  const headers = {
    'Content-Type': 'application/json'
  };
  const data = {
    email: email,
    password: password
  };
  console.log("URL Used " + API_BASE_URL);
  return Axios.post(API_BASE_URL + "/security/authenticate", data, { headers: headers })
}

export function getOrganizations() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  // Make the GET request using fetch
  return Axios.get(API_BASE_URL + "/organizations", { headers: headers })
}

export function getEmployees() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/employees", { headers: headers })
}
export function getEmployeeById(id) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/employees/" + id, { headers: headers })
}
export function saveEmployee(firstName,
  lastName,
  birthDate,
  email,
  socialSecurityNumber) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {

    firstName: firstName,
    lastName: lastName,
    birthDate: birthDate,
    email: email,
    socialSecurityNumber: socialSecurityNumber
  };
  return Axios.post(API_BASE_URL + "/employees", data, { headers: headers })
}

export function deleteEmployees(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/employees/delete", { data: selectedIds, headers: headers })
}

export function getCompagnyTypes() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  // Make the GET request using fetch
  return Axios.get(API_BASE_URL + "/compagnyTypes", { headers: headers })
}

export function getFournisseurs() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/compagny/type=supplier", { headers: headers })
}

export function getLegalstatusAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/legalstatus", { headers: headers })
}

export function saveLegalStatus(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/legalstatus", data, { headers: headers })
}

export function deleteLegalStatus(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/legalstatus/delete", { data: selectedIds, headers: headers })
}

export function deleteFournisseurs(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/compagny/delete", { data: selectedIds, headers: headers })
}

export function saveFournisseur(
  nom,
  siretNumber,
  legalStatus,
  socialCapital,
  tvaNumber,
  registrationDate,
  website,
  type) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    name: nom,
    siretNumber: siretNumber,
    tvaNumber: tvaNumber,
    registrationDate: registrationDate,
    socialCapital: socialCapital,
    website: website,
    type: type,
    legalStatus: legalStatus
  };
  return Axios.post(API_BASE_URL + "/compagny", data, { headers: headers })
}

export function saveOrganization(
  name,
  siretNumber,
  mobileNumber,
  phoneNumber,
  legalStatus,
  tvaNumber,
  registrationDate,
  website,
  addressLine1,
  addressLine2,
  zipcode,
  city,
  country,
  email
) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    name: name,
    siretNumber: siretNumber,
    mobileNumber: mobileNumber,
    phoneNumber: phoneNumber,
    legalStatus: legalStatus,
    tvaNumber: tvaNumber,
    registrationDate: registrationDate,
    website: website,
    adressLine1: addressLine1,
    adressLine2: addressLine2,
    zipcode: zipcode,
    city: city,
    country: country,
    email: email
  };

  return Axios.post(API_BASE_URL + "/organizations", data, { headers: headers })
}

export function getPaymentTypeAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/paymenttype", { headers: headers })
}
export function savePaymentType(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/paymenttype", data, { headers: headers })
}
export function deletePaymentType(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/paymenttype/delete", { data: selectedIds, headers: headers })
}

export function getAdressTypeAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/addresstype", { headers: headers })
}
export function saveAdressType(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/addresstype", data, { headers: headers })
}
export function deleteAdressType(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/addresstype/delete", { data: selectedIds, headers: headers })
}

export function getSimulationStatusAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulationstatus", { headers: headers })
}
export function saveSimulationStatus(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/simulationstatus", data, { headers: headers })
}
export function deleteSimulationStatus(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/simulationstatus/delete", { data: selectedIds, headers: headers })
}
export function deleteOrganization(selectedId) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/organizations/" + selectedId, { headers: headers })
}

export function getconfigurationSimValuesAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/configurationsimvalue", { headers: headers })
}
export function saveconfigurationSimValues(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/configurationsimvalue", data, { headers: headers })
}
export function deleteconfigurationSimValues(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/configurationsimvalue/delete", { data: selectedIds, headers: headers })
}
export function getConfigurationsFraisKiloAll() {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/coefficientkilo", { headers: headers })
}
export function saveConfigurationsFraisKilo(code, label) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    codeLabel: code,
    Label: label
  };
  return Axios.post(API_BASE_URL + "/coefficientkilo", data, { headers: headers })
}
export function deleteConfigurationsFraisKilo(selectedIds) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.delete(API_BASE_URL + "/coefficientkilo/delete", { data: selectedIds, headers: headers })
}
export function calculateFraisKilo(distance, chevaux, nbreOfDays) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/coefficientkilo/calculatePSW?chevaux=" + chevaux + "&distance=" + distance + "&nbreOfDays=" + nbreOfDays, { headers: headers })
}

export function getSimulation(dailyPrice, nbreOfDays, FraisProIk, ndf, epargne) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const data = {
    dailyPrice: dailyPrice,
    nbrJours: nbreOfDays,
    savingAmount: epargne,
    fraisKilom: FraisProIk,
    email: "",
    idEmployee: 0,
    employeeName: "",
    employeeJob: "",
    ndf: ndf
  };

  return Axios.post(API_BASE_URL + "/simulation/calculate", data, { headers: headers })
}

export function calculateFraisTtv(FratTTVinputs) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.post(API_BASE_URL + "/coefficientkilo/calculateTTV", JSON.stringify(remplacerVidesParZero(FratTTVinputs)), { headers: headers })
}

export function saveSimulation({ data, saveData }) {

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };

  const Apidata = {
    status: data.status,
    dailyPrice: data.dailyPrice,
    nbreOfDays: data.nbreOfDays,
    savingAmount: data.savingAmount,
    id_consultant: saveData.employees_id,
    chiffreAffaire: data.chiffreAffaire,
    fraisDeGestion: data.fraisDeGestion,
    panierRepas: data.panierRepas,
    fraisAnnexe: data.fraisAnnexe,
    ik: data.ik,
    ndf: data.ndf,
    fraisprofessionnels: data.fraisprofessionnels,
    primeDapportDaffaire: data.primeDapportDaffaire,
    indeminiteCongePaye: data.indeminiteCongePaye,
    salaireBrute: data.salaireBrute,
    salaireNetImposable: data.salaireNetImposable,
    salaireOptimiseNet: data.salaireOptimiseNet,
    reserve: data.salaireOptimiseNet,
    reste: data.reste,
    compteCourant: data.compteCourant,
    name: saveData.name,
    salaireBrutDebase: data.salaireBrutDebase,
    email: saveData.email,
    duty: saveData.fonction
  }
  return Axios.post(API_BASE_URL + "/simulation/save", Apidata, { headers: headers })
}

export function getSimulations( type){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/simulations?type="+type, { headers: headers })
}

export function setEnModeNegotiation( id){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/PasserEnNegotiation?id="+id, { headers: headers }) 
}

export function setEnModeSignature(simulationId , simulation){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  const updatedSimulation = {
    ...simulation,  // Inclure les propriétés existantes de simulation
    simulationId: simulationId  // Ajouter simulationId
  };
  return Axios.post(API_BASE_URL + "/simulation/PasserEnSignature",updatedSimulation, { headers: headers })
}

export function setEnModeAnnulation( id){
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/PasserContratEnResiliation?id="+id, {responseType: 'arraybuffer',headers: headers}) 
}

export function getImageSimulation( id){
  const headers = {
    'Content-Type': 'IMAGE_JPEG',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/ImageSimulation?id="+id, { headers: headers })
}

function remplacerVidesParZero(objet) {
  const nouvelObjet = {};
  for (const prop in objet) {
    if (objet.hasOwnProperty(prop)) {
      nouvelObjet[prop] = objet[prop] === "" ? 0 : parseFloat(objet[prop]);
    }
  }
  return nouvelObjet;
}

export function getEMployeedIDSimulationByID(id) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem("token")
  };
  return Axios.get(API_BASE_URL + "/simulation/simulationEmplyeeId?id="+id, {headers: headers})
}

