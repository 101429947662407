import React from "react";
import s from "./Footer.module.scss";
import packageJson from "../../../package.json";

const Footer = () => {
    const version = packageJson.version;
  return (
    <div className={s.footer}>
      <span className={s.footerLabel}>2024 &copy; N2E IT  - v {version}</span>
     
    </div>
  )
}

export default Footer;
