import React from 'react';
import "./ProgressSteps.scss"

const ProgressSteps = ({activeStep}) => {
  return (
    <div className="stepper-wrapper">
       <div className={`stepper-item ${activeStep >= 1 ? 'completed' : ''}`}>
        <div className="step-counter">01</div>
        <div className="step-name">Consultant</div>
      </div>
      <div className={`stepper-item ${activeStep >= 2 ? 'completed' : ''}`}>
        <div className="step-counter">02</div>
        <div className="step-name">Client</div>
      </div>
      <div className={`stepper-item ${activeStep >= 3 ? 'active' : ''}`}>
        <div className="step-counter">03</div>
        <div className="step-name">Contrat</div>
      </div>
    </div>
  );
};

export default ProgressSteps;