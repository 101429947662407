import React, { useEffect, useState } from 'react';
import { Collapse, Card, CardHeader, CardBody, Label, FormGroup, InputGroup, InputGroupAddon, Input, Row, Col } from 'reactstrap';
import './CustomAccordion.scss'
import voitureicon from "../../assets/52120.png"
import ttvicon from "../../assets/3122193.png"
import { calculateFraisKilo, calculateFraisTtv } from '../../services/configService';
import { formatNmbr } from '../../services/OutilService'
const CustomAccordion = ({ nbreOfDays, ndf, setNdf, FraisProIk, setFraisProIk }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => setIsOpen(!isOpen);
    const [selectedSquare, setSelectedSquare] = useState(0);
    const [fraisTtvConf, setFraisTtvConf] = useState(0)
    const [fraisTTV, setfraisTTV] = useState(0);
    const [FraisKilo, setFraisKilo] = useState(0);
    const [FraisKiloConf, setFraisKiloConf] = useState(0);
    const [FraisProTot, setFraisProTot] = useState(0);

    useEffect(() => {
        const fraisProIkValue = selectedSquare === 0 ? FraisKiloConf : fraisTtvConf;
        setFraisProIk(fraisProIkValue);
    }, [selectedSquare, FraisKiloConf, fraisTtvConf]);

    useEffect(() => {
        const fraisProTotValue = parseFloat(FraisProIk) + parseFloat(ndf);
        setFraisProTot(fraisProTotValue);
    }, [FraisProIk, ndf]);

    const handleSquareClick = (index) => {
        setSelectedSquare(index);
    };
    const handleRefreshFraisKilo = async (e) => {
        e.preventDefault();
        setFraisKiloConf(FraisKilo);

    }
    const handleRefreshFraisTTV = (e) => {
        e.preventDefault();
        setFraisTtvConf(fraisTTV);
    }

    return (
        <Card className="custom-card mb-2">
            <CardHeader className="custom-card-header" onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
                <div className="row">
                    <Label className="col-sm-9">Frais professionnels</Label>
                    <Label className="col-sm-3">
                        {formatNmbr(FraisProTot)} €
                        <span className={isOpen ? 'caret-4-desc' : 'caret-4-asc'}></span>
                    </Label>
                </div>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <FormGroup className="row ml-2">
                        <InputGroup className="NDF-Input mt-2 ml-1">
                            <Label for="days" className="col-sm-6">NDF</Label>
                            <Input type="number" value={ndf} onChange={(e) => setNdf(e.target.value)} />
                            <InputGroupAddon addonType="append">€ / mois</InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                    <Row>
                        <Col>
                            <Square
                                selected={selectedSquare === 0}
                                onClick={() => handleSquareClick(0)}
                                header="Frais Kilométriques"
                                price={FraisKiloConf}
                                icon={voitureicon}
                                nbreOfDays={nbreOfDays}
                                FraisKilo={FraisKilo}
                                setFraisKilo={setFraisKilo}
                                handleRefreshFraisKilo={handleRefreshFraisKilo}
                                handleRefreshFraisTTV={handleRefreshFraisTTV}
                                type={1}
                            />
                        </Col>
                        <Col>
                            <Square
                                selected={selectedSquare === 1}
                                onClick={() => handleSquareClick(1)}
                                header="Frais Télétravail"
                                price={fraisTtvConf}
                                icon={ttvicon}
                                nbreOfDays={nbreOfDays}
                                type={2}
                                handleRefreshFraisTTV={handleRefreshFraisTTV}
                                fraisTTV={fraisTTV}
                                setfraisTTV={setfraisTTV}

                            />
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    );
}
const Square = ({ selected, onClick, header, price, icon, nbreOfDays, FraisKilo, setFraisKilo, handleRefreshFraisKilo, type, handleRefreshFraisTTV, fraisTTV, setfraisTTV }) => {
    const color = selected ? '#00fa9a' : '#f0f0f0';
    const [showModal, setShowModal] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [distance, setDistance] = useState(0);
    const [chevaux, setChevaux] = useState(0);
    const handleSelectChange = (event) => {
        setChevaux(event.target.value);
    };
    const [FratTTVinputs, setInputs] = useState({
        superficieTotal: '',
        loyer: '',
        assuranceHabitation: '',
        electrecite: '',
        taxeOm: '',
        superficieTTV: '',
        teleMobile: '',
        boxInter: '',
        taxeHabitation: '',
        navigo: ''
    });
    const handleCalculateFraisTtv = (e) => {
        e.preventDefault();
        try {
            calculateFraisTtv(FratTTVinputs).then(
                res => {
                    setfraisTTV(res.data);
                })
        } catch (error) {
            console.error(error);
        }
    }

    const handleInputChangeFTTV = (e) => {
        const { name, value } = e.target;
        setInputs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDetailClick = (e) => {
        e.preventDefault();
        const buttonRect = e.target.getBoundingClientRect();
        setModalPosition({
            top: buttonRect.top + buttonRect.height,
            left: buttonRect.left
        });
        setShowModal(!showModal);
    };

    useEffect(() => {
        if (chevaux !== 0 && distance !== 0 && typeof distance !== 'undefined' && nbreOfDays !== 0) {
            try {
                calculateFraisKilo(distance, chevaux, nbreOfDays).then(
                    res => {
                        setFraisKilo(res.data);
                    })
            } catch (error) {
                console.error(error);
            }
        }
    }, [distance, chevaux, nbreOfDays]);



    return (
        <div
            style={{
                position: 'relative',
                width: '230px',
                height: '100px',
                backgroundColor: color,
                margin: '10px',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <input
                type="checkbox"
                style={{
                    position: 'absolute',
                    top: '5px',
                    left: '5px',
                    zIndex: 1,
                    transform: 'scale(1.5)',
                    width: '15px',
                    height: '15px',
                    border: '2px solid black',
                    borderRadius: '5px',
                }}
                onChange={() => { }}
                checked={selected}
            />
            <div className="simulation-container">
                <img
                    src={icon}
                    alt="Person initial"
                    className="simulation-image"
                />
                <div className="simulation-details">
                    <div className="simulation-header">{header}</div>
                    <div className="simulation-price">{formatNmbr(price)}</div>
                </div>
            </div>
            <a
                href="#"
                className="ancre-details"
                onClick={handleDetailClick}
            >
                Details
            </a>
            {showModal &&
                <div className="small-modal" >
                    {(type === 1) && <div className="modal-content">
                        <span className="close" onClick={handleDetailClick}>&times;</span>
                        <InputGroup className="NDF-Input mt-2 ml-1">
                            <Label for="days" className="col-sm-6">Nbre de CH. Fiscaux</Label>
                            <Input type="select" name="chevaux" value={chevaux} onChange={handleSelectChange}>
                                <option value="0">Selectionnez les chevaux</option>
                                <option value="4">4 CV</option>
                                <option value="5">5 CV</option>
                                <option value="6">6 CV</option>
                                <option value="7">7 CV et plus</option>
                            </Input>
                        </InputGroup>
                        <InputGroup className="NDF-Input mt-2 ml-1">
                            <Label for="days" className="col-sm-6">Distance Parcourue</Label>
                            <Input type="number" name="distance" placeholder="distance" value={distance}
                                onChange={(e) => setDistance(e.target.value)} />
                            <InputGroupAddon addonType="append">Km/Jrs</InputGroupAddon>
                        </InputGroup>
                        <div className="text-simulation-calcul">
                            <span>{formatNmbr(FraisKilo)} €</span>

                        </div>

                        <button className="mt-2" onClick={handleRefreshFraisKilo} >Valider </button>
                    </div>}
                    {(type === 2) && <div className="modal-content">
                        <span className="close" onClick={handleDetailClick}>&times;</span>
                        <div className="row mt-4">
                            <div className="col-sm-6">
                                <div className="input-group-container">
                                    <InputGroup className="NDF-Input pb-3">
                                        <Input name="superficieTotal" type="number" onChange={handleInputChangeFTTV} value={FratTTVinputs.superficieTotal} placeholder="Superficie domicile" />
                                        <InputGroupAddon addonType="append">m*m</InputGroupAddon>
                                    </InputGroup>
                                    <InputGroup className="NDF-Input pb-3">
                                        <Input name="loyer" type="number" onChange={handleInputChangeFTTV} value={FratTTVinputs.loyer} placeholder="Mont. de loyer €" />
                                        <InputGroupAddon addonType="append">€</InputGroupAddon>
                                    </InputGroup>
                                    <InputGroup className="NDF-Input pb-3">
                                        <Input name="assuranceHabitation" type="number" onChange={handleInputChangeFTTV} value={FratTTVinputs.assuranceHabitation} placeholder="Mont. D'assur. habitation €" />
                                        <InputGroupAddon addonType="append">€</InputGroupAddon>
                                    </InputGroup>
                                    <InputGroup className="NDF-Input pb-3">
                                        <Input name="electrecite" type="number" onChange={handleInputChangeFTTV} value={FratTTVinputs.electrecite} placeholder="Abon. Electricité €" />
                                        <InputGroupAddon addonType="append">€</InputGroupAddon>
                                    </InputGroup>
                                    <InputGroup className="NDF-Input pb-3">
                                        <Input name="taxeOm" type="number" onChange={handleInputChangeFTTV} value={FratTTVinputs.taxeOm} placeholder="Taxe OM" />
                                        <InputGroupAddon addonType="append">€</InputGroupAddon>
                                    </InputGroup>
                                    <button className="mt-2" onClick={handleCalculateFraisTtv} >Calculer </button>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group-container">
                                    <InputGroup className="NDF-Input pb-3">
                                        <Input name="superficieTTV" type="number" onChange={handleInputChangeFTTV} value={FratTTVinputs.superficieTTV} placeholder="superficie TTV" />
                                        <InputGroupAddon addonType="append">m*m</InputGroupAddon>
                                    </InputGroup>
                                    <InputGroup className="NDF-Input pb-3">
                                        <Input name="teleMobile" type="number" onChange={handleInputChangeFTTV} value={FratTTVinputs.teleMobile} placeholder="Téléphone mobile €" />
                                        <InputGroupAddon addonType="append">€</InputGroupAddon>
                                    </InputGroup>
                                    <InputGroup className="NDF-Input pb-3">
                                        <Input name="boxInter" type="number" onChange={handleInputChangeFTTV} value={FratTTVinputs.boxInter} placeholder="Box internet €" />
                                        <InputGroupAddon addonType="append">€</InputGroupAddon>
                                    </InputGroup>
                                    <InputGroup className="NDF-Input pb-3">
                                        <Input name="taxeHabitation" type="number" onChange={handleInputChangeFTTV} value={FratTTVinputs.taxeHabitation} placeholder="Taxe d'habitation €" />
                                        <InputGroupAddon addonType="append">€</InputGroupAddon>
                                    </InputGroup>
                                    <InputGroup className="NDF-Input pb-3">
                                        <Input name="navigo" type="number" onChange={handleInputChangeFTTV} value={FratTTVinputs.navigo} placeholder="Navigo" />
                                        <InputGroupAddon addonType="append">€</InputGroupAddon>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>

                        <div className="text-simulation-calcul">
                            <span>{formatNmbr(fraisTTV)} €</span>
                        </div>
                        <button className="mt-2" onClick={handleRefreshFraisTTV} >Valider </button>
                    </div>}
                </div>
            }
        </div>
    );
};
export default CustomAccordion;