import React, { useState, useEffect } from "react";
import { getSimulationStatusAll, saveSimulationStatus, deleteSimulationStatus } from "../../services/configService";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  FormFeedback
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-toastify/dist/ReactToastify.css';
import showToast from "../../components/NotificationToast/toastUtils";
import {startLoading, stopLoading} from "../../actions/loading";
import {useDispatch} from "react-redux";

const SimulationStatus = () => {

  const [formList, setFormList] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [code, setCode] = useState('');
  const [label, setLabel] = useState('');
  const [codeValid, setCodeValid] = useState(true);
  const [labelValid, setLabelValid] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(startLoading());
    try {
      const res = await getSimulationStatusAll();
      setFormList(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(stopLoading());
    }

  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow.id !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = async () => {
    if (code.trim() === '') {
      setCodeValid(false);
    } else {
      setCodeValid(true);
    }
    if (label.trim() === '') {
      setLabelValid(false);
    } else {
      setLabelValid(true);
    }
    if (code.trim() !== '' && label.trim() !== '') {
      try {
        await saveSimulationStatus(code, label);
        showToast('Le statut de simlation a été enregistré avec succès.' );
        toggleAddModal();
        fetchData();
      } catch (error) {
        showToast('Le statut de simlation n\'a pas pu être enregistré.',"error" );
      }
    }
  };

  const handleDelete = async () => {
    const selectedIds = selectedRows.map(row => row.id);
    try {
      await deleteSimulationStatus(selectedIds);
      showToast('Les statuts de simlation sélectionnés ont été supprimés avec succès.' );
      setSelectedRows([]);
    } catch (error) {
      showToast('ne erreur s\'est produite lors de la suppression des statuts de simlation.',"error" );
    }
    toggleDeleteModal();
    fetchData();
  }

  const columns = [{
    dataField: 'codeLabel',
    text: 'code',
    sort: true
  }, {
    dataField: 'label',
    text: 'Titre',
    sort: true
  }];

  return (
    <Row className="mb-4">
      <Col>
        <Widget>
          <div className="button-container mb-1 mt-2">
            <button className="button mr-2" onClick={toggleAddModal}>
              <i className="eva eva-plus-outline icon" />
            </button>
            {selectedRows.length === 1 && (
              <>
                <button className="button  mr-2" onClick={toggleEditModal}>
                  <i className="eva eva-edit-outline icon" />
                </button>
              </>
            )}
            {selectedRows.length >= 1 && (
              <>
                <button className="button mr-2" onClick={toggleDeleteModal}>
                  <i className="eva eva-trash-2-outline icon" />
                </button>
              </>
            )}
          </div>
          <BootstrapTable
            keyField='id'
            data={formList}
            columns={columns}
            pagination={paginationFactory({
              sizePerPage: 10,
              hideSizePerPage: true,
              paginationOptions: {
                showStartEndPageButtons: false
              }
            })}
            wrapperClasses="table-responsive"
            striped
            hover
            condensed
            search
            selectRow={{
              mode: 'checkbox',
              onSelect: handleOnSelect,
              onSelectAll: handleOnSelectAll
            }}
            bootstrap4
            classes="table-sm"
          />
        </Widget>
      </Col>
      <Modal isOpen={isAddModalOpen} toggle={toggleAddModal}>
        <ModalHeader toggle={toggleAddModal}>Ajouter un statut de simlation</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="code">Code</Label>
            <Input
              type="text"
              id="code"
              placeholder="Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              valid={codeValid}
              invalid={!codeValid}
            />
            <FormFeedback>Le code est requis.</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="label">Label</Label>
            <Input
              type="text"
              id="label"
              placeholder="Label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              valid={labelValid}
              invalid={!labelValid}
            />
            <FormFeedback>Le label est requis.</FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleAddModal}>
            Annuler
          </Button>
          <Button color="primary" onClick={handleAdd}>
            Ajouter
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Supprimer un statut de simlation </ModalHeader>
        <ModalBody>
          Voulez-vous vraiment supprimer les statuts de simlation sélectionnés ?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Annuler
          </Button>
          <Button color="danger" onClick={handleDelete}>
            Supprimer
          </Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
}

export default SimulationStatus;
