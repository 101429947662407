import React, { useState } from "react";
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem } from "reactstrap";
import { Droppable, Draggable } from 'react-beautiful-dnd';

const DynamicCardComponent = ({ droppableId, title, users , icon: Icon}) => {
    const colors = ['#0d1daa', '#fa333b', '#ff1bff', '#50f790'];

    return (
        <Droppable droppableId={droppableId}>
            {(provided) => (
                <Card className="Perspection-card" {...provided.droppableProps} innerRef={provided.innerRef}>
                    <CardBody>
                        <CardTitle tag="h5" style={{ textAlign: 'center' }}>
                            {Icon && <Icon style={{ marginRight: '4px' }} />}
                            {title}</CardTitle>
                        <ListGroup>
                            {users.map((user, index) => {
                                const randomColor = colors[Math.floor(Math.random() * colors.length)];
                                return (
                                    <Draggable key={user.id + ""} draggableId={user.id + ""} index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <ListGroupItem className="custom-list-group" action>
                                                    <div className="person-container">
                                                        <ImageDisplay id={user.id} name={user.name} randomColor={randomColor} />
                                                        <div className="person-details">
                                                            <div className="person-name">{user.name}</div>
                                                            <div className="person-role">{user.fonction}</div>
                                                        </div>
                                                        <div className="person-date">{formatDate(user.updatedAt)}</div>
                                                    </div>
                                                </ListGroupItem>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </ListGroup>
                    </CardBody>
                </Card>
            )}
        </Droppable>
    );
};
function formatDate(isoDate) {
    const date = new Date(isoDate);
    return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
}
function getInitial(name) {
    if (!name) return "";
    const words = name.split(" ");
    if (words.length < 2) return words[0].charAt(0).toUpperCase();
    const firstInitial = words[0].charAt(0).toUpperCase();
    const secondInitial = words[1].charAt(0).toUpperCase();
    return firstInitial + secondInitial;
}
const ImageDisplay = ({ id , name ,randomColor}) => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const [imageError, setImageError] = useState(false);
    const handleImageError = () => {
        setImageError(true);
    };

    return (
        <div>
            {imageError ? (
                <div className="initial-circle" style={{ backgroundColor: randomColor }}>{getInitial(name)}</div>
            ) : (
                <img
                    className="image-initital"
                    src={`${API_BASE_URL}/simulation/ImageSimulation?id=${id}`}
                    alt="Sim"
                    onError={handleImageError}
                />
            )}
        </div>
    );
};
export default DynamicCardComponent