import React, { useEffect, useState } from 'react';
import { Collapse, Card, CardHeader, CardBody, Label, Table } from 'reactstrap';
import './CustomAccordion.scss'
import { getconfigurationSimValuesAll } from "../../services/configService";
import {useDispatch} from "react-redux";
import {startLoading, stopLoading} from "../../actions/loading";

const CustomAccordion2 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => setIsOpen(!isOpen);
    const [formList, setFormList] = useState([]);
    const dispatch = useDispatch();
    const fetchData = async () => {
        dispatch(startLoading());
        try {
            const res = await getconfigurationSimValuesAll();
            setFormList(res.data);
        } catch (error) {
            console.error(error);
        }finally {
            dispatch(stopLoading());
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Card className="custom-card mb-2">
            <CardHeader className="custom-card-header" onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
                <div className="row">
                    <Label className="col-sm-9">Configurations Simulation</Label>
                    <Label className="col-sm-3">

                        <span className={isOpen ? 'caret-4-desc' : 'caret-4-asc'}></span>
                    </Label>
                </div>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <Table borderless>
                        {formList.map(e =>
                        (
                            <tr><td>{e.label}</td> <td>{e.value}</td></tr>
                        ))
                        }
                    </Table>
                </CardBody>
            </Collapse>
        </Card>
    );
}

export default CustomAccordion2;