import React, { useState, useEffect } from "react";
import { getFournisseurs, getLegalstatusAll, saveFournisseur, deleteFournisseurs } from "../../services/configService";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from "reactstrap";
import Widget from "../../components/Widget/Widget";
import './FournisseursPage.scss'
import showToast from '../../components/NotificationToast/toastUtils'
import {useDispatch} from "react-redux";
import {startLoading, stopLoading} from "../../actions/loading";
const Fournisseurs = () => {
  const [formList, setFormList] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [nom, setNom] = useState('');
  const [siretNumber, setSiret] = useState('');
  const [socialCapital, setSocialCapital] = useState('');
  const [website, setWebsite] = useState('');
  const [type, setType] = useState(["supplier"])
  const [tvaNumber, setTva] = useState('');
  const [registrationDate, setDateEnregistrement] = useState(null);
  const [legalStatus, setLegalStatus] = useState('');
  const [nomValid, setnomValid] = useState(true);
  const [siretNumberValid, setsiretNumberValid] = useState(true);
  const [legalStatusValid, setlegalStatusValid] = useState(true);
  const [legalStatusOptions, setLegalStatusOptions] = useState([]);
  const dispatch = useDispatch();
  const columns = [
    { dataField: 'name', text: 'Nom', sort: true },
    { dataField: 'siretNumber', text: 'Siret' },
    { dataField: 'socialCapital', text: 'Social Capital' },
    { dataField: 'tvaNumber', text: 'TVA Number' },
    {
      dataField: 'registrationDate', text: 'Date d\'enregistrement',
      formatter: (cell, row) => formatDate(cell)
    }
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const fetchData = async () => {
    dispatch(startLoading());
    try {
      const res = await getFournisseurs();
      setFormList(res.data);
    } catch (error) {
      console.error(error);
    }finally {
      dispatch(stopLoading());
    }
  };
  useEffect(() => {
    fetchData();
    fetchLegalStatusOptions();
  }, []);
  const fetchLegalStatusOptions = async () => {
    try {
      const res = await getLegalstatusAll();
      setLegalStatusOptions(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow.id !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };
  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);
  const handleAdd = async () => {
    if (nom.trim() === '') {
      setnomValid(false);
    } else {
      setnomValid(true);
    }
    if (siretNumber.trim() === '') {
      setsiretNumberValid(false);
    } else {
      setsiretNumberValid(true);
    }
    if (legalStatus.trim() === '') {
      setlegalStatusValid(false);
    } else {
      setlegalStatusValid(true);
    }
    if (nom.trim() !== '' && siretNumber.trim() !== '' && legalStatus.trim()) {
      try {
        await saveFournisseur(
          nom,
          siretNumber,
          legalStatus,
          socialCapital,
          tvaNumber,
          registrationDate,
          website,
          type);
        showToast('Le Fournisseur a été enregistré avec succès.' );
        toggleAddModal();
        fetchData();
      } catch (error) {
        showToast("Le Fournisseur n'a pas pu être enregistré. " + error.message , "error");
      }
    }
  }
  const handleDelete = async () => {
    const selectedIds = selectedRows.map(row => row.id);
    try {
      await deleteFournisseurs(selectedIds);
      showToast("Les fournisseurs sélectionnés ont été supprimés avec succès.");

      setSelectedRows([]);
    } catch (error) {
      showToast("Une erreur s'est produite lors de la suppression des fournisseurs.", "error");
    }
    toggleDeleteModal();
    fetchData();
  }

  return (
    <Row className="mb-4">
      <Col>
        <Widget>
          <div className="button-container mb-1 mt-2">
            <button className="button mr-2" onClick={toggleAddModal}>
              <i className="eva eva-plus-outline icon" />
            </button>
            {selectedRows.length === 1 && (
              <>
                <button className="button  mr-2" onClick={toggleEditModal}>
                  <i className="eva eva-edit-outline icon" />
                </button>
              </>
            )}
            {selectedRows.length >= 1 && (
              <>
                <button className="button mr-2" onClick={toggleDeleteModal}>
                  <i className="eva eva-trash-2-outline icon" />
                </button>
              </>
            )}
          </div>
          <BootstrapTable
            keyField='id'
            data={formList}
            columns={columns}
            pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true })}
            wrapperClasses="table-responsive"
            striped
            hover
            condensed
            search
            selectRow={{
              mode: 'checkbox',
              onSelect: handleOnSelect,
              onSelectAll: handleOnSelectAll
            }}
            bootstrap4
            classes="table-sm"
          />
        </Widget>
      </Col>
      <Modal isOpen={isAddModalOpen} toggle={toggleAddModal}>
        <ModalHeader toggle={toggleAddModal}>Ajouter un Fournisseur</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="nom">Nom:</Label>
            <Input
              type="text"
              id="nom"
              placeholder="Nom"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              valid={nomValid}
              invalid={!nomValid}
            />
          </FormGroup>
          <FormGroup>
            <Label for="siret">Siret:</Label>
            <Input
              type="text"
              id="siret"
              placeholder="Siret"
              value={siretNumber}
              onChange={(e) => setSiret(e.target.value)}
              valid={siretNumberValid}
              invalid={!siretNumberValid}
            />
          </FormGroup>
          <FormGroup>
            <Label for="legalStatus">Statut légal:</Label>
            <Input
              type="select"
              id="legalStatus"
              value={legalStatus}
              onChange={(e) => setLegalStatus(e.target.value)}
              valid={legalStatusValid}
              invalid={!legalStatusValid}
            >
              <option value="">Sélectionner un statut</option>
              {legalStatusOptions.map(option => (
                <option key={option.id} value={option.id}>{option.codeLabel} - {option.label}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="socialCapital">Capital social:</Label>
            <Input
              type="text"
              id="socialCapital"
              placeholder="Capital social"
              value={socialCapital}
              onChange={(e) => setSocialCapital(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="tva">TVA:</Label>
            <Input
              type="number"
              id="tva"
              placeholder="TVA"
              value={tvaNumber}
              onChange={(e) => setTva(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="dateEnregistrement">Date d'enregistrement:</Label>
            <Input
              type="date"
              id="dateEnregistrement"
              placeholder="Date d'enregistrement"
              value={registrationDate}
              onChange={(e) => setDateEnregistrement(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="siteEntreprise">Site web de Fournisseur</Label>
            <Input
              type="String"
              id="siteEntreprise"
              placeholder="Site web"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleAddModal}>
            Annuler
          </Button>
          <Button color="primary" onClick={handleAdd}>
            Ajouter
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Supprimer Fournisseur</ModalHeader>
        <ModalBody>
          Voulez-vous vraiment supprimer les fournisseurs sélectionnés ?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Annuler
          </Button>
          <Button color="danger" onClick={handleDelete}>
            Supprimer
          </Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
}

export default Fournisseurs;
