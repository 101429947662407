import React, { useState, useEffect } from "react";
import { getEmployees, saveEmployee, deleteEmployees } from "../../services/configService";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from "reactstrap";
import Widget from "../../components/Widget/Widget";
import paginationFactory from 'react-bootstrap-table2-paginator';
import './EmployeesPage.scss'
import showToast from '../../components/NotificationToast/toastUtils'
import {startLoading, stopLoading} from "../../actions/loading";
import {useDispatch} from "react-redux";

const Employees = () => {

  const [formList, setFormList] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [socialSecurityNumber, setSocialSecurityNumber] = useState("");
  const dispatch = useDispatch();
  const columns = [{
    dataField: 'lastName',
    text: 'Nom',
    sort: true
  }, {
    dataField: 'firstName',
    text: 'Prenom'
  }, {
    dataField: 'birthDate',
    text: 'Date Naissance',
    formatter: (cell, row) => formatDate(cell)
  }, {
    dataField: 'socialSecurityNumber',
    text: 'N Sec Sos'
  }, {
    dataField: 'email',
    text: 'mail'
  }, {
    dataField: 'duty',
    text: 'Fonction'
  }];
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(startLoading());
    try {
      const res = await getEmployees();
      setFormList(res.data);
    } catch (error) {
      showToast("Erreur " + error.message , "error");
    }finally {
      dispatch(stopLoading());
    }
  };
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow.id !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };
  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);

  const handleAdd = async () => {

    try {
      await saveEmployee(
        firstName,
        lastName,
        birthDate,
        email,
        socialSecurityNumber);
      showToast('L\'employée a été enregistré avec succès.');
      toggleAddModal();
      fetchData();
    } catch (error) {
      showToast("L'employée n'a pas pu être enregistré. " + error.message , "error");
    }
  }
  const handleDelete = async () => {
    const selectedIds = selectedRows.map(row => row.id);
    try {
      await deleteEmployees(selectedIds);
      showToast('Les employées sélectionnés ont été supprimés avec succès.');
      setSelectedRows([]);
    } catch (error) {
      showToast('Une erreur s\'est produite lors de la suppression des employées.' , "error");
    }
    toggleDeleteModal();
    fetchData();
  }

  return (
    <Row className="mb-4">
      <Col>
        <Widget>
          <div className="button-container mb-1 mt-2">
            <button className="button mr-2" onClick={toggleAddModal}>
              <i className="eva eva-plus-outline icon" />
            </button>
            {selectedRows.length === 1 && (
              <>
                <button className="button  mr-2" onClick={toggleEditModal}>
                  <i className="eva eva-edit-outline icon" />
                </button>
              </>
            )}
            {selectedRows.length >= 1 && (
              <>
                <button className="button mr-2" onClick={toggleDeleteModal}>
                  <i className="eva eva-trash-2-outline icon" />
                </button>
              </>
            )}
          </div>
          <BootstrapTable
            keyField='id'
            data={formList}
            columns={columns}
            pagination={paginationFactory({
              sizePerPage: 10,
              hideSizePerPage: true,
              paginationOptions: {
                showStartEndPageButtons: false
              }
            })}

            wrapperClasses="table-responsive"
            striped
            hover
            condensed
            search
            selectRow={{
              mode: 'checkbox',
              onSelect: handleOnSelect,
              onSelectAll: handleOnSelectAll
            }}
            bootstrap4
            classes="table-sm"
          />
        </Widget>
      </Col>
      <Modal isOpen={isAddModalOpen} toggle={toggleAddModal}>
        <ModalHeader toggle={toggleAddModal}>Ajouter un Fournisseur</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="firstName">Prénom:</Label>
            <Input
              type="text"
              id="firstName"
              placeholder="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Nom:</Label>
            <Input
              type="text"
              id="lastName"
              placeholder="Nom"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="birthDate">Date de Naissance:</Label>
            <Input
              type="date"
              id="birthDate"
              placeholder="Date de Naissance"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email:</Label>
            <Input
              type="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="socialSecurityNumber">Numéro de Sécurité Sociale:</Label>
            <Input
              type="text"
              id="socialSecurityNumber"
              placeholder="Numéro de Sécurité Sociale"
              value={socialSecurityNumber}
              onChange={(e) => setSocialSecurityNumber(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleAddModal}>
            Annuler
          </Button>
          <Button color="primary" onClick={handleAdd}>
            Ajouter
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Supprimer Fournisseur</ModalHeader>
        <ModalBody>
          Voulez-vous vraiment supprimer les fournisseurs sélectionnés ?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Annuler
          </Button>
          <Button color="danger" onClick={handleDelete}>
            Supprimer
          </Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
}

export default Employees;
