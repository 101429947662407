import {login} from '../services/configService'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export function receiveLogin() {
  return {
    type: LOGIN_SUCCESS
  };
}

function loginError(payload) {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

// logs the user out
export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    localStorage.removeItem('token');
    dispatch(receiveLogout());
  };
}

export function loginUser(creds) {
  return (dispatch) => {
    dispatch(receiveLogin());
    if (creds.email.length > 0 && creds.password.length > 0) {
      // Utiliser la fonction login pour authentifier l'utilisateur
      login(creds.email, creds.password)
        .then(response => {
          // Authentification réussie
          localStorage.setItem('token', response.data.id_token); 
          creds.props.history.push('/organizations');
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            // Si l'erreur est une réponse 401 (Non autorisé)
            dispatch(loginError('L\'adresse e-mail ou le mot de passe est incorrect. Veuillez réessayer.'));
          } else {
            // Une autre erreur s'est produite
            dispatch(loginError('Une erreur s\'est produite. Veuillez réessayer.'));
          }
        });
    } else {
      dispatch(loginError('Quelque chose s\'est mal passé. Veuillez réessayer.'));
    }
  };
}

