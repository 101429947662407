import React from 'react';
import './LoadingPage.scss'
import { useSelector } from 'react-redux';
const LoadingPage = () => {
    const isLoading = useSelector(state => state.loading.loading);

    if (!isLoading) {
        return null;
    }

    return (
        <div className="loading-overlay">
            <div className="spinner-container">
                <div className="lds-default">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingPage;