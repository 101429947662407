import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';

const NegociationModal = (props) => {

    return (
        <Modal isOpen={props.ModalNegociationOpen} toggle={props.toggleModalNegociation} className="form-input">
            <ModalHeader toggle={props.toggleModalNegociation}>Négociation de la simulation </ModalHeader>
            <ModalBody>
                <Form>
                    <div className="form-row">
                        Vous êtes sur le point de passer le statut de cette simulation de proposition à "négociation".
                        Êtes-vous sûr de vouloir continuer ? Si oui, cliquez sur le bouton "Valider".
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.handleValidate} >Valider</Button>
            </ModalFooter>
        </Modal>
    );
};

export default NegociationModal;