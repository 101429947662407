import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import SofiaLogo from "../Icons/SofiaLogo.js";
import cn from "classnames";

const Sidebar = (props) => {

const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false)

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerSidebarOpen(true)
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false)
      }, 0);
    }
  }, [props.sidebarOpened])

  return (
    <nav className={cn(s.root, {[s.sidebarOpen]: burgerSidebarOpen})} >
      <header className={s.logo}>
        <SofiaLogo/>
        <span className={s.title}>N2E-IT CRM</span>
      </header>
      <ul className={s.nav}>
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Tableau de Bord"
          isHeader
          iconName={<i className={'eva eva-home-outline'}/>}
          link="/home/dashboard"
          index="dashboard"
        />
        <h5 className={s.navTitle}>MANAGER</h5>
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Configuration"
          isHeader
          iconName={<i className={'eva eva-settings-2-outline'}/>}
          link="/home/uielements"
          index="uielements"
          childrenLinks={[
            {
              header: 'Statut Juridi..', link: '/home/configuration/StatutJuridique',
            },
            {
              header: 'Fournisseurs', link: '/home/configuration/fournisseurs',
            },
            {
              header: 'Employées', link: '/home/configuration/Employees',
            },
            {
              header: 'Type de paiement ', link: '/home/configuration/PaymentType',
            },
            {
              header: 'Type d\'adresse', link: '/home/configuration/AdressType',
            },
            {
              header: 'statut simulat...', link: '/home/configuration/SimulationStatus',
            },
            {
              header: 'Prospec Conf...', link: '/home/configuration/ConfigurationSimValue',
            },
            {
              header: 'Prospec Conf...', link: '/home/configuration/ConfigurationsFraisKilo'
            }
          ]}
        />
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Factures"
          isHeader
          iconName={<i className={'eva eva-text-outline'}/>}
          link="/home/typography"
          index="typography"
        />
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="CR d'Activité"
          isHeader
          iconName={<i className={'eva eva-calendar-outline'}/>}
          link="/home/CRA"
          index="Compte Rendu d'Activité"
        />
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Prospections"
          isHeader
          iconName={<i className={'eva eva-grid-outline'}/>}
          link="/home/prospections"
          index="prospections"
        />

      </ul>
    </nav>
  );
}

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
