import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Input } from 'reactstrap';

const CancelSignedModal = (props) => {

    return (
        <Modal isOpen={props.modalCancelOpen} toggle={props.toggleModalCancel} className="form-input">
            <ModalHeader toggle={props.toggleModalCancel}>Résiliation du contrat </ModalHeader>
            <ModalBody>
                <Form>
                    <div className="form-row">
                    </div><div className="col-md-6 mb-3">
                        <Label for="startDate">Date de fin</Label>
                        <Input type="date" id="startDate" placeholder="Date de fin" />
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.handleValidate} >Valider</Button>
            </ModalFooter>
        </Modal>
    );
};

export default CancelSignedModal;