import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./MultiOptionButton.scss"
const MultiOptionButton = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    
    return (
        <div className="d-flex justify-content-end">
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret className="dropdown-toggle-custom" color="primary">
                    Valider
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => props.handleOptionClick("Option 1")}>Save</DropdownItem>
                    <DropdownItem onClick={() => props.handleOptionClick("Option 2")}>Save and Send Email</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default MultiOptionButton;